import {local} from './storage';
// import Modal from '@springernature/springernature-modal/js/modal';
import Modal from './modal';

const feedbackWidget = () => {
  const feedbackTrigger = document.querySelector('.c-feedback-widget-button');

  if (feedbackTrigger) {
    const feedbackForm = document.querySelector('[data-component-modal="feedback"]');
    const surveyId = feedbackForm.querySelector('iframe') ? feedbackForm.querySelector('iframe').src.split('/')[6] : 'no-id';

    // eslint-disable-next-line no-unused-vars
    const feedbackModal = new Modal(feedbackForm);

    feedbackTrigger.setAttribute('href', '#');

    const checkForPreviousSubmissionCookie = () => {
      if (local.isAvailable()) {
        const previousFeedback = local.getItem('feedback-form');
        if (previousFeedback) {
          const json = JSON.parse(previousFeedback);
          const expiryDate = new Date(json.expires).getTime();
          const now = new Date().getTime();

          if (json.id === surveyId) {
            if (expiryDate - now < 1) {
              local.removeItem('feedback-form');
              feedbackTrigger.classList.remove('js-hide');
            }
          }
        } else {
          feedbackTrigger.classList.remove('js-hide');
        }
      }
    };

    if (feedbackForm) {
      const hideFormButton = feedbackForm.querySelector('[data-object-hide-feedback-form]');
      if (hideFormButton) {
        hideFormButton.addEventListener('click', () => {
          if (local.isAvailable()) {
            const expiryDate = new Date();
            expiryDate.setMonth(expiryDate.getMonth() + 2);
            local.setItem('feedback-form', JSON.stringify({id: surveyId, expires: expiryDate.toISOString()}));
          }
          feedbackTrigger.classList.add('js-hide');
        });
      }

      checkForPreviousSubmissionCookie();
    }
  }
};

module.exports = feedbackWidget;
