import $ from 'jquery';
import eventTracker from './eventTracker';
import articleSelect from './article-select';
import publishingRouteSelect from './route-select';
import feedbackWidget from './feedback-widget';

$(document).ready(() => {
  new eventTracker();

  articleSelect();
  feedbackWidget();
  publishingRouteSelect();
});
