class Storage {
  constructor(storageType) {
    this.storage = window[storageType];
    this.available = this.isAvailable();
  }

  isAvailable() {
    const testKey = 'storage-module-test';

    if (this.available !== undefined) {
      return this.available;
    }

    try {
      this.storage.setItem(testKey, 'test-data');
      this.storage.removeItem(testKey);
      this.available = true;
    } catch (err) {
      this.available = false;
    }

    return this.available;
  }

  getItem(key) {
    if (!this.available) {
      return;
    }

    return this.storage.getItem(key);
  }

  setItem(key, value) {
    if (!this.available) {
      return;
    }

    return this.storage.setItem(key, typeof value === 'string' ? value : JSON.stringify(value));
  }

  removeItem(key) {
    if (this.available) {
      return this.storage.removeItem(key);
    }
  }
}

export const session = new Storage('sessionStorage');
export const local = new Storage('localStorage');
