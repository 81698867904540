import serialiseForm from './serialise-form';

const publishingRouteSelect = () => {
  const articleTypeSubmit = document.querySelector('[data-component-publishing-route-submit]');
  const articleForm = document.querySelector('[data-component-publishing-route-select-form]');

  if (articleForm) {
    articleForm.addEventListener('change', (event) => {
      event.preventDefault();
      event.stopPropagation();

      const vals = {};
      serialiseForm(articleForm).split('&').forEach(function (s) {
        vals[s.split('=')[0]] = s.split('=')[1];
      });
      const jFlowId = vals['jFlowId'];
      const subType = vals['publishing_route'];

      articleTypeSubmit.setAttribute('data-track-label', `Continue button - ${jFlowId} - ${subType}`);

      const checked = articleForm.querySelector('input:checked');
      if (checked) {
        [].slice.apply(articleForm.querySelectorAll('.selected')).forEach(elem => elem.classList.remove('selected'));
        checked.parentNode.classList.add('selected');
      }
    });
  }
};

module.exports = publishingRouteSelect;
