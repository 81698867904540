const serialiseForm = form => {
  if (!form) {
    return false;
  }

  let serialized = [];
  let types = ['text', 'radio', 'checkbox', 'textarea', 'select-one', 'email', 'hidden'];

  [].slice.apply(form.elements).forEach(el => {
    let type = el.type;
    let name = el.name;
    let value = el.value;

    if (types.indexOf(type) > -1) {
      let str;
      if (el.type === 'radio' || type === 'checkbox') {
        if (el.checked === true) {
          str = name + '=' + value;
          serialized.push(str);
        }
      } else {
        str = name + '=' + encodeURIComponent(value);
        serialized.push(str);
      }
    }
  });

  return serialized.join('&');
};

export default serialiseForm;
