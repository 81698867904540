import $ from 'jquery';
class eventTracker {
  constructor() {
    this.clickAction = ".button-cta";
    this.rightClick = ".button-right-cta";
    this.selectChange = ".select-cta";
    this.attachEventListners();
  }
  attachEventListners() {
    $('body').on("click", this.clickAction, this.gaTrackEvents);
    $('body').on("contextmenu", this.rightClick, this.gaTrackEvents);
    $('body').on("change", this.selectChange, this.gaTrackEvents);
  }

  gaTrackEvents(event) {
    if ($(this).hasClass( "select-cta" )) {
      $(this).attr('data-track-label', $(this).val());
    }
    const eventData = {
      category: $(this).attr('data-track-category'),
      action: event.type || $(this).attr('data-track-action'),
      label: $(this).attr('data-track-label'),
      value: 1
    };

    if (typeof ga !== "undefined") {
      ga('send', 'event', eventData.category, eventData.action, eventData.label, eventData.value); // eslint-disable-line no-undef
    } else {
      console.log(eventData); // eslint-disable-line no-console
    }
  }
}

module.exports = eventTracker;
